import React from 'react'
import Index from '../views/Index'
import Edit from '../views/Edit'
import Login from '../views/Login'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../app/store'
import { useParams, useNavigate } from 'react-router-dom'

const Centralbody = (props) => {

    const user = useSelector((state)=> state.user)
    const menu = useSelector((state)=> state.menu)
    const {id} = useParams()
    const {url,nomeSing,nomePlur,apiModel,enableSave,enableDelete} = props

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logOut())
        navigate(process.env.REACT_APP_LOCAL_SUBFOLDER+'/')
    }

     /* New model creation logic managed in Edit */
    return (
        <div className={'hero'+(menu.show?' menu-open':'')}>
        {
        user.logged_in && <><div className='w-100' style={{height: 50}}></div><div className='corner-right me-3' style={{position: 'fixed',zIndex: 99}}>
          <button className='mcr-btn small-btn' onClick={()=>{handleLogout()}}>Logout</button>
        </div></>
        }
  
        {/* <div className=''>
          {!user.logged_in && <Login />}
          {user.logged_in && !id && <Index nomeSing={'prodotto'} nomePlur={'prodotti'}/>}
          {user.logged_in && id && <Edit nomeSing={'prodotto'} />}
        </div> */}

        <div className=''>
          {!user.logged_in && <Login />}
          {user.logged_in && <Index nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} />}
          {user.logged_in && id && <Edit nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} />}
        </div>

      </div>
    )
}

export default Centralbody