import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setData, setDataStructure } from '../app/store'
import TableComponent from '../components/TableComponent';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { get_backend_filter, get_backend_sort_model } from '../helper'

function Index(props) {

  const {nomeSing,nomePlur,url,apiModel,enableSave} = props

  const navigate = useNavigate()
  const [loading,setLoading] = useState(false)
  const [loading2, setloading2] = useState(false)
  const [loadErr, setLoadErr] = useState(null)
  const [freeSearchString,setFreeSearchString] = useState(localStorage.getItem(nomeSing+'_freeSearchString')?localStorage.getItem(nomeSing+'_freeSearchString'):'')
  const [tmpString,setTmpString] = useState(localStorage.getItem(nomeSing+'_freeSearchString')?localStorage.getItem(nomeSing+'_freeSearchString'):'')
  const [triggerReset,setTriggerReset] = useState(0)

  const dataStructure = useSelector((state)=> state.dataStructure)
  const dispatch = useDispatch()


    // Load data
    useEffect(() => {
      setLoading(true)
      setLoadErr(null)
      const getData = async(filterModel,sortModel) => {
        //const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_INDEX_GET_URL;
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL+ apiModel + '/search';
        const config = {
          headers: {'Authorization': '12345'},
          params: {}
        }
        let requestBody=get_backend_filter(filterModel,sortModel,freeSearchString)
        try{
          const response = await axios.post(url,requestBody,config)
          const recdata = response.data.result
          if(typeof recdata === 'object'){
            dispatch(setData(recdata))
          }
          setLoading(false)
        } catch(error){
          if(error.response.data.error.code && error.response.data.error.description){
            setLoadErr('Errore '+error.response.data.error.code+': '+error.response.data.error.description)
          } else {
            setLoadErr('Errore di rete caricando i dati!')
          }
          setLoading(false)
        }
      }
      const inState = JSON.parse(localStorage.getItem(nomeSing+'_initialState'))
        const columnState = JSON.parse(localStorage.getItem(nomeSing+'_columnState'))
        const filterModel=(inState&&inState.filter)?inState.filter.filterModel:{}
        const sortBy=get_backend_sort_model(columnState)
      getData(filterModel,sortBy)
    },[setLoading,dispatch,nomeSing,apiModel,freeSearchString]
    )

    // Load data structure (schema)
    useEffect(() => {
      setloading2(true)
      const getStrData = async () => {
        //const url = process.env.REACT_APP_LOCAL_BACKEND_URL + process.env.REACT_APP_STRUCTURE_GET_URL
        const url = process.env.REACT_APP_LOCAL_BACKEND_MANIFEST_URL
        const config = {
          headers: { 'Authorization': '12345' },
          params: {}
        }
        try {
          const response = await axios.get(url, config)
          const recdata = response.data
          if (recdata) {
            let modeldata = recdata.schema[apiModel].fields
            let mydata = [];
            for (let k in modeldata) {
              mydata.push({ name: k, label: (typeof modeldata[k].name === 'string' ? modeldata[k].name : modeldata[k].name.it), type: modeldata[k].type, model: modeldata[k].model, required: modeldata[k].required, min: modeldata[k].min, max: modeldata[k].max,})
            }
            dispatch(setDataStructure(mydata))
          }
          setloading2(false)
        } catch (error) {
          console.log('Loading object structure error: ' + error)
          setloading2(false)
        }
      }
      getStrData()
    }, [setloading2,apiModel,dispatch]
    )

    
    const handleFreeSearchValueChange = (event) => {
      setTmpString(event.target.value)
      // Function getData in useEffect has dependency freeSearchString to reload data as soon as search string is changed
    }

    // Adds a delay of 1 second from end of typing free search string to actual API call
    useEffect(()=>{
      const t = setTimeout(()=>{
        setFreeSearchString(tmpString)
        localStorage.setItem(nomeSing+'_freeSearchString',tmpString)
      },1000)
      return () => {
        clearTimeout(t)
      }
    },[tmpString,nomeSing])

    const handleTableReset = (event) => {
      setTriggerReset(triggerReset+1)
      setFreeSearchString(''); // This is done by useEffect tied to setTmpString, but setting here skips update timer
      setTmpString('');
      localStorage.setItem(nomeSing+'_freeSearchString','')
    }
  
    return (
      <div className='w-100'>
        <div className='container-fluid'>
          {enableSave && <div className="row">
            <div className="col-12 text-end">
              <button className='btn mcr-btn btn-with-icon ms-0 mb-2' onClick={()=>{navigate(process.env.REACT_APP_LOCAL_SUBFOLDER+url+'/new')}}>
              <span className="material-symbols-rounded header-icon">add</span> Nuovo</button></div>
          </div>}
          <div className="row">
            <div className="col-12 pb-2"><h3 className='text-even'>Lista {nomePlur}</h3></div>
          </div>
          <div className="row">
            <div className="col-8 col-md-9 col-lg-10 pb-2">
              <label className='form-label text-even h6'>Ricerca libera:</label>
              <div className="input-group mb-3">
                <input type="text" value={tmpString} onChange={handleFreeSearchValueChange} className="form-control bg-dark text-even" style={{borderRight: 'none'}} placeholder="Cerca..." aria-label="Cerca..." aria-describedby="search-icon"/>
                <span className="input-group-text material-symbols-rounded bg-dark text-even" id="search-icon">search</span>
              </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 d-flex justify-content-end align-items-end pb-3'>
            <button className='btn mcr-btn warning btn-with-icon ms-0 mb-2' onClick={()=>{handleTableReset()}}>
              <span className="material-symbols-rounded header-icon">filter_alt_off</span> Reset filtri</button>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{height: 700}}>
          {!loadErr && (loading || loading2) && <h3 className='h3 text-even'>Caricamento...</h3>}
          {loadErr && <h3 className='h3 text-even'>{loadErr}</h3>}
          {!loadErr /* && data.tableData.length */ && dataStructure.structure.length && <TableComponent nomeSing={nomeSing} freeSearch={freeSearchString} nomePlur={nomePlur} apiModel={apiModel} url={url} triggerReset={triggerReset}/>}
          {/* {!loadErr && !loading && !loading2 && !data.tableData && <h3 className='h3 mt-5 text-even'>Nessun dato presente</h3>} */}
        </div>
      </div>
    );
  }

  export default Index;
  