import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as Logo } from '../logo.svg'
import { setShow } from '../app/store'
import { useNavigate } from 'react-router-dom'

const Topbar = (props) => {

    const menu = useSelector((state)=> state.menu)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {url} = props

    const handleMenuButtonClick = () => {
      dispatch(setShow(!menu.show))
      if(!menu.show){
        navigate(url)
      }
    }
    

  return (
    <header>
        <div className="container h-100 d-flex justify-content-between align-items-center">
            
            <div className='d-flex justify-content-center align-items-center'>
              <span className='d-md-none text-even me-2 material-symbols-rounded menu-hamburger' onClick={()=>{handleMenuButtonClick()}}>{menu.show?'menu_open':'menu'}</span>
              <Logo alt="Logo" className="logo_img" />
            </div>
            <div className="h-100 d-flex justify-content-end align-items-center">
                <span className="material-symbols-rounded header-icon">person</span>
                <span className='header-text me-3'>{user.logged_in ? 'Benvenuto Nome Cognome': 'Utente non loggato'}</span>
                <h2 className="text-white mx-2 mb-0">{'UI tecnica'}</h2>
            </div>
        </div>

    </header>
  )
}

export default Topbar
