import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setShow } from '../app/store'

const Menu = (props) => {

const links = props.links
const menu = useSelector((state)=> state.menu)
const user = useSelector((state)=> state.user)
const dispatch = useDispatch()

  return (
    <>
    {user.logged_in && <div className="sidebar-menu flex-grow-1">
        <ul className='ps-2 mt-4'>
        {links.map((link, index) => (
            <li key={index} className='mt-2'>
            <NavLink to={link.url} className={'nav-link'} onClick={()=>{dispatch(setShow(!menu.show))}}>
                <span className="material-symbols-rounded shift-down">{link.nome_icona}</span> {link.nome_link} 
            </NavLink>
            </li>
        ))}
        </ul>
    </div>}
    </>
  );
};

export default Menu;
